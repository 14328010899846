import React, { useState } from "react";
import "./style.scss";
import Layout from "../../Layout";
import SEO from "../../seo";

export default function RulesAndRegulations() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const student = (
    <ul className="rnr__section2__list">
      <li>
        School Almanac should be brought daily to school by It is not to be used
        as a rough note book but used regularly by each student to note homework
        and instructions given by the teachers.
      </li>

      <li>
        Each child should read all the instructions given in the diary
        thoroughly. All students are expected to learn all the shlokas and songs
        to enhance their performance in Value Education
      </li>

      <li>
        Students who come to school on their own should reach the school latest
        by 8 am. Late comers shall be sent back home. Students who use own
        transport to reach school are to especially note this point.
      </li>

      <li>
        No student is allowed to leave the school by his personal conveyance
        without permission or meet outsiders or ex-students during school hours.
      </li>

      <li>
        The student must submit an application explaining his / her Absenteeism
        for more than seven days without prior permission from the Principal
        will result in the name being struck off rolls.
      </li>

      <li>
        Students should always be neatly dressed in their well ironed school
        uniform with their shoes Boys are required to keep hair trim and short.
        No fancy haircuts allowed. No fancy jewellery is to be worn. Growing of
        nails, use of nail paint and colouring of hair is forbidden. Long hair
        is to be plaited neatly.
      </li>

      <li>
        Students are expected to keep the campus neat and they are advised to
        make use of the dustbins provided for throwing bits of papers and Say
        'No' to Polybags.
      </li>

      <li>
        Students are advised not to bring costly articles i.e., fountain pens,
        jewellery etc. to school. The school will not be responsible for
        articles or cash lost. Borrowing or lending money or other articles is
        strictly forbidden.
      </li>

      <li>
        Students are advised to deposit unclaimed articles found in the school
        with their class teacher and check for lost articles from the HM's.
      </li>

      <li>
        Students who come to school under the care of their escort, should not
        go home on their own, but should wait for them after the school is
        Parents are responsible to ensure that their wards are dropped and
        picked up from the school on time.
      </li>

      <li>
        Students are not allowed to bring Mobile Phones, iPods or Diskman to If
        such articles are found with any student, they will be confiscated by
        school till the end of term. In case of emergency, access to telephone
        is allowed with the permission of the HMs / Senior Secondary
        Coordinators.
      </li>

      <li>
        The students of Chinmaya Vidyalaya are expected to set a standard of
        good conduct and good behaviour in the school, in the bus, at home and
        public places i.e. act as an ambassador of the school in all situations
        creating goodwill for the institution everywhere. They are expected to
        greet their teachers and elders whenever they meet them.
      </li>

      <li>
        Parents are advised not to call for their wards from the class in order
        to attend any social function except in unavoidable.
      </li>

      <li>
        Appointment with dentist / doctor may desirably be taken on a Saturday
        or after school hours on working days.
        <ul>
          <li>
            Group or individual play in the class room and verandah is strictly
            prohibited.
          </li>
          <li>Running in the corridors / verandah is not allowed.</li>
        </ul>
      </li>

      <li>
        No student shall shout or whistle in the school building or use foul
        language inside or outside.
      </li>

      <li>
        Students must take care of the school Damaging school furniture and
        property, writing on the walls and disfiguring visuals is strictly
        forbidden. Any damage done to the school property will be recovered
        individually or collectively from the defaulter / defaulters.
      </li>

      <li>
        The school reserves the right to suspend a student for indiscipline or
        any act of any repeated act will cause expulsion from the school as per
        norms.
      </li>

      <li>
        The school insists on instilling worthy values of life and a deep sense
        of responsibility based on their understanding of duties.
      </li>

      <li>
        A firm foundation is to be laid in education and character-building.
      </li>

      <li>
        The school insists on a parallel and equal parental effort in developing
        self-study habits under parental.
      </li>

      <li>Leave without reason is to be discouraged.</li>

      <li>
        Prior permission for outstation travel is to be sought from the
        Principal
      </li>
    </ul>
  );

  const parent = (
    <ul className="rnr__section2__list">
      <li>
        Parents are expected to co-operate in the work of the school by
        enforcing regularity and they should take personal interest in the
        child’s academic performance and personal hygiene.
      </li>
      <li>
        Parents should check the diary daily and note the teacher's Monthly
        report and term examination results should be scrutinized and signed by
        the parents.
      </li>

      <li>
        Parents who seek information or who wish to communicate with respect to
        their ward's class problems may do so through a note to the class
        Parents can meet teachers personally on any working Saturday as
        specified in the School Events or otherwise by appointment.
      </li>

      <li>
        Parents should help students to treat the diary with care and during a
        mutilated diary will have to be replaced by a new one.
      </li>

      <li>
        Parents' attendance for the PTM and other orientation programmes are
        obligatory. Parents are requested to cooperate as and when they receive
        intimation from the class teacher for betterment of their wards.
      </li>

      <li>
        Parents entering school must carry their Parent ID cards. Without Parent
        ID cards parents will not be allowed in the Vidyalaya.
      </li>

      <li>
        During school functions parents are requested to kindly remain in their
        seats till the function is over and take away their wards as and when
        the event is over.
      </li>

      <li>
        Parents and guardians who drop their wards to school are requested to do
        so just at the school gates and not right up to the class.
      </li>

      <li>Parents must ensure that their wards reach school in time.</li>

      <li>
        An SMS will be sent to the parents in case of cancellation of any exam
        or any other important.
      </li>

      <li>
        Parents need to be vigilant about their ward's friends, their phone
        calls etc.
      </li>

      <li>
        Parents must ensure that a healthy home environment is maintained and a
        proper night's sleep is availed by their wards.
      </li>

      <li>
        To cultivate respect and dignity of human labour, parents should assign
        small chores to their wards.
      </li>
    </ul>
  );

  let conductJSX = null;

  if (currentIndex === 0) {
    conductJSX = student;
  } else if (currentIndex === 1) {
    conductJSX = parent;
  }

  return (
    <Layout>
      <SEO
        title="Code of Conduct | Chinmaya Vidyalaya"
        description="Chinmaya Vidyalaya is known for maintaining a high standard of discipline in the campus. Explore the student and parent code of conduct."
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="rnr">
        <h1 className="heading">Rules & Regulations</h1>

        <p className="rnr__text">
          Chinmaya Vidyalaya is known for maintaining a high standard of
          discipline in the campus and shall continue this tradition to ensure a
          free and congenial environment for all its students to pursue their
          studies without any distraction.
        </p>

        <div className="rnr__preamble">
          <h2>Preamble</h2>
          <ul>
            <li>
              Every student in the school shall at all times display good
              behavior, show diligence in studies, maintain decorum and dignity,
              take due interest in co-curricular activities, observe a code of
              conduct both within and outside the campus in a manner befitting
              the student of a school of national stature and observe all the
              rules of discipline of the school.
            </li>
            <li>
              Each student shall show due respect and courtesy to the teachers,
              administrators, and other employees in and outside the school and
              good neighbourly behaviour towards the fellow students and others.
            </li>
            <li>
              Any violation of the code of conduct or breach of any rule or
              regulation of the school as laid down in the school diary by a
              student shall constitute an act of indiscipline and shall make him
              / her liable for disciplinary action which will be taken by the
              duly constituted Disciplinary.
            </li>
          </ul>
        </div>

        <div className="rnr__section2">
          <div className="rnr__tabs">
            <div
              className={`rnr__tabs__button ${
                currentIndex === 0 && "rnr__tabs__button--active"
              }`}
              onClick={() => setCurrentIndex(0)}
            >
              Student Code of Conduct
            </div>
            <div
              className={`rnr__tabs__button ${
                currentIndex === 1 && "rnr__tabs__button--active"
              }`}
              onClick={() => setCurrentIndex(1)}
              //   className="rnr__tabs__button"
            >
              Parent Code of Conduct
            </div>
          </div>

          {conductJSX}
        </div>

        <div className="rnr__section3">
          <h2 className="heading">Acts of Indiscipline</h2>

          <div className="rnr__text">
            The following shall constitute acts of indiscipline and the students
            indulging in any of them shall be liable to disciplinary action
            against them. The Principal retains the right to take appropriate
            action or even leading to expulsion in case the offence is of a
            serious nature as per norms prevalent.
          </div>

          <div className="rnr__icons row">
            <div className="col col-12 col-md-4">
              <div className="rnr__icons__card">
                <img
                  alt=""
                  src="https://chinmayavvdelhi.ac.in/images/Blackele/No_phone_icon.png"
                ></img>
                <span>No mobiles Allowed</span>
              </div>
            </div>
            <div className="col col-12 col-md-4">
              <div className="rnr__icons__card">
                <img
                  alt=""
                  src="https://chinmayavvdelhi.ac.in/images/Blackele/No_damage_property_icon.png"
                ></img>
                <span>No damage to school propety</span>
              </div>
            </div>
            <div className="col col-12 col-md-4">
              <div className="rnr__icons__card">
                <img
                  alt=""
                  src="https://chinmayavvdelhi.ac.in/images/Blackele/no_whistling_icon.png"
                ></img>
                <span>No whistling or shouting</span>
              </div>
            </div>
          </div>

          <ul className="rnr__section3__list">
            <li>
              Disobeying the teachers and displaying misdemeanor within and
              outside the School.
            </li>

            <li>
              Indulging in vandalism / violence and damaging school property or
              property of any other student.
            </li>

            <li>
              Use of mobile, iPod, camera or any electronic gadget within the
              campus or school.
            </li>

            <li>
              Quarrelling, fighting and passing derogatory remarks in the school
              premises against the fellow students / teacher / employees /
              canteen workers.
            </li>

            <li>
              Indulging in ragging directly or indirectly, which is strictly
              prohibited as per Supreme Court.
            </li>

            <li>
              Consumption, carrying and sale of drugs / alcohol / intoxicants /
              tobacco etc.
            </li>

            <li>
              Any type of harassment whether physical, verbal, mental,
              emotional, sexual or electronic.
            </li>

            <li>Late arrival in class or bunking of assigned classes.</li>
            <li>Frequent default in submitting work assigned.</li>
            <li>Not wearing the proper school uniform.</li>
            <li>
              Bursting crackers or throwing colour on one another within or near
              the school
            </li>
            <li>Not carrying the school almanac or the school identity.</li>
            <li>Trespassing on school campus property after school timings.</li>
            <li>
              Damaging, scribbling or tearing pages from library books, texts or
              exercise books.
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
}
